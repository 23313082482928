
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import BuyButton from "../../../common/buttons/BuyButton";
const Roadmap = ({ t }) => {
    const roadmap = t('home:roadmap', {}, { returnObjects: true });
    const RoadmapItem = ({ item, i }) => {
        return <li data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200 * (i + 1)} className='roadmap_item'>
            <div className='roadmap_item_img'>
                <img src={`/img/home/roadmap/${item.icon}_row.svg`} alt="GOODEGGS virtual dating" width={227} height={362} className={`row_img ${item.icon}`}/>
                <picture>
                    <source media="(min-width: 577px)" srcSet={`/img/home/roadmap/${item.icon}.webp`} type="image/webp"/>
                    {/*<source*/}
                    {/*    media="(max-width: 576px)"*/}
                    {/*    srcSet={`/img/home/roadmap/${item.icon}_small.webp`}*/}
                    {/*    type="image/webp"*/}
                    {/*/>*/}
                    <source media="(min-width: 577px)" srcSet={`/img/home/roadmap/${item.icon}.png`}/>
                    {/*<source*/}
                    {/*    media="(max-width: 576px)"*/}
                    {/*    srcSet={`/img/home/roadmap/${item.icon}_small.png`}*/}
                    {/*/>*/}
                    <img src={`/img/home/roadmap/${item.icon}.png`} alt="GOODEGGS virtual dating" width={179} height={279} className='main_img'/>
                </picture>
            </div>
            <h3 className={'roadmap_item_title'}>{item.title_1}</h3>
            <h3 className={'roadmap_item_title'}>{item.title_2}</h3>
            <ol className={'roadmap_item_list'}>
                {item.list.map(el => {
                return <li key={el.key}>{el.text}</li>;
            })}
            </ol>
        </li>;
    };
    return (<section className='roadmap' id={'roadmap'}>
            <div className="container">
                <h2 className='roadmap_title'>{roadmap.title}</h2>
                <ul className="roadmap_wrapper">
                    {roadmap.items.map((item, i) => {
            return <RoadmapItem i={i} key={item.icon} item={item}/>;
        })}
                </ul>
                <Link className='roadmap_cta' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                    <BuyButton text={roadmap.cta}/>
                </Link>
            </div>
        </section>);
};
export default Roadmap;

    async function __Next_Translate__getStaticProps__191ffafc02b__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Roadmap/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191ffafc02b__ as getStaticProps }
  