
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const FeaturedIn = ({ t }) => {
    const featured = t('home:featured', {}, { returnObjects: true });
    const items = [
        { name: 'yahoo', w: '75', h: '28' },
        { name: 'benzinga', w: '117', h: '17' },
        { name: 'utoday', w: '108', h: '17' },
        { name: 'bitcoinist', w: '130', h: '19' }
    ];
    return (<section className='featured'>
            <div className='container'>
                <div className='featured_wrapper'>
                    <h2 className='featured_title' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200}>{featured.title}</h2>
                    <div className='featured_items'>
                        {items.map((el, i) => {
            return <div key={el.name} className='featured_item' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100 * (i + 3)}>
                                <img src={`/img/home/featured/${el.name}.svg`} alt={`$GEGG featured in`} width={el.w} height={el.h} className=''/>
                            </div>;
        })}
                    </div>
                </div>
            </div>
        </section>);
};
export default FeaturedIn;

    async function __Next_Translate__getStaticProps__191ffafbf5e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/FeaturedIn/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191ffafbf5e__ as getStaticProps }
  