
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Accordion from "../../../common/accordion";
const Faqs = ({ t }) => {
    const faqs = t('home:faqs', {}, { returnObjects: true });
    return (<section className='faqs'>
            <div className="container">
                <div className="faqs_wrapper">
                    <div className="faqs_title">
                        <h2 className={'faqs_title_title'}>{faqs.title}</h2>
                        <picture>
                            <source media="(min-width: 577px)" srcSet={`/img/home/faqs/faqs_bird.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/home/faqs/faqs_bird_small.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/home/faqs/faqs_bird.png`}/>
                            <source media="(max-width: 576px)" srcSet={`/img/home/faqs/faqs_bird_small.png`}/>
                            <img src={`/img/home/faqs/faqs_bird.png`} alt="GOODEGGS FAQ" width={364} height={476} className='faq_img'/>
                        </picture>
                    </div>
                    <div className='faqs_list'>
                        <Accordion faqs_list={faqs.list || []}/>
                    </div>
                </div>
            </div>
        </section>);
};
export default Faqs;

    async function __Next_Translate__getStaticProps__191ffafc030__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/faqs/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191ffafc030__ as getStaticProps }
  