
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
const Audit = () => {
    return (<section className={'audit'}>
            <div className="container">
                <div className={'audit_data'}>
                    <div className="audit_data_l">
                        <img src={'/img/home/audit/coinsult.svg'} alt={'GOODEGG Augit'} width={216} height={216}/>
                        <Link href={'/'} target={'_blank'}>
                            Coinsult
                        </Link>
                    </div>
                    <div className="audit_data_c">
                        <img src={'/img/home/audit/safe.svg'} alt={'GOODEGG Augit'} width={216} height={216}/>
                    </div>
                    <div className="audit_data_r">
                        <Link href={'/'} target={'_blank'}>
                            Solidproof
                        </Link>
                        <img src={'/img/home/audit/solidproof.svg'} alt={'GOODEGG Augit'} width={216} height={216}/>
                    </div>
                </div>
                <div className={'mobile_links'}>
                    <div className="audit_data_m">
                        <img src={'/img/home/audit/coinsult.svg'} alt={'GOODEGG Augit'} width={216} height={216}/>
                        <Link href={'/'} target={'_blank'}>
                            Coinsult
                        </Link>
                    </div>
                    <div className="audit_data_m">
                        <img src={'/img/home/audit/solidproof.svg'} alt={'GOODEGG Augit'} width={216} height={216}/>
                        <Link href={'/'} target={'_blank'}>
                            Solidproof
                        </Link>
                    </div>
                </div>
            </div>
        </section>);
};
export default Audit;

    async function __Next_Translate__getStaticProps__191ffafc01b__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Audit/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191ffafc01b__ as getStaticProps }
  