
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const Universe = ({ t }) => {
    const universe = t('home:universe', {}, { returnObjects: true });
    return (<section className='universe'>
            <div className="container">
                <div className="universe_wrapper">
                    <h2 className='universe_title'>{universe.title}</h2>
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/universe/dating.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/universe/dating_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/universe/dating.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/universe/dating_small.png`}/>
                        <img src={`/img/home/universe/dating.png`} alt="GOODEGGS virtual dating" width={595} height={682} className='u_image u_dating' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100}/>
                    </picture>
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/universe/play_to_date.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/universe/play_to_date_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/universe/play_to_date.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/universe/play_to_date_small.png`}/>
                        <img src={`/img/home/universe/play_to_date.png`} alt="GOODEGGS virtual dating" width={553} height={522} className='u_image u_p_t_d' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200}/>
                    </picture>
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/universe/nfts.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/universe/nfts_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/universe/nfts.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/universe/nfts_small.png`}/>
                        <img src={`/img/home/universe/nfts.png`} alt="GOODEGGS virtual dating" width={497} height={514} className='u_image u_nfts' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={300}/>
                    </picture>
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/universe/competitions.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/universe/competitions_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/universe/competitions.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/universe/competitions_small.png`}/>
                        <img src={`/img/home/universe/competitions.png`} alt="GOODEGGS virtual dating" width={718} height={400} className='u_image u_competitions' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400}/>
                    </picture>
                </div>
            </div>
        </section>);
};
export default Universe;

    async function __Next_Translate__getStaticProps__191ffafc00c__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Universe/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191ffafc00c__ as getStaticProps }
  